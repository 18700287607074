/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - ProductDayAvailability.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { DateString }         from 'library/utils/dateTime';
import { QueryFunction }      from '@tanstack/react-query';
import Collection             from 'library/Collection';
import { makeRoute }          from 'library/modelsUtils';
import { HTTPMethod }         from 'library/Request';
import { makeRequest }        from 'library/Request/Request';

export interface IProductDayAvailability {
  product_id: number,
  date: DateString,

  /**
   * The number of faces of the product
   */
  faces: number,

  /**
   * How long is the product open/active on this day, in  minutes
   */
  open_length_min: number,

  /**
   * How many spots in the loop can be booked
   */
  reservable_spots_count: number,

  /**
   * How many spots are already booked
   */
  reserved_spots_count: number,

  /**
   * How many spots are available; Not booked already
   */
  free_spots_count: number,

  /**
   * The loop duration of the product on this day in milliseconds
   */
  loop_duration_ms: number,

  /**
   * The length of a spot in the loop in milliseconds
   */
  spot_length_ms: number,

  /**
   * Is the product fully unavailable ?
   */
  unavailable: 1 | 0,

  /**
   * Why is this product fully unavailable ? (in english)
   */
  unavailability_reason_en: string | null,

  /**
   * Why is this product fully unavailable ? (in french)
   */
  unavailability_reason_fr: string | null,
}

class ProductDayAvailabilityModel extends Model<IProductDayAvailability> {
  _slug = 'availability';

  basePath = '/v1/availabilities';

  attributesTypes: { [attr in keyof IProductDayAvailability]?: (sourceAttr: any) => IProductDayAvailability[attr] } = {};

  key = 'product_id' as const;

  routesAttributes: { [attr in ModelPersistAction]: (keyof IProductDayAvailability)[] } = {
    create: [],
    save  : [],
  };
}

export class ProductDayAvailability extends ProductDayAvailabilityModel implements IProductDayAvailability {
  date!: DateString;

  faces!: number;

  free_spots_count!: number;

  product_id!: number;

  reservable_spots_count!: number;

  reserved_spots_count!: number;

  unavailable!: 1 | 0;

  unavailability_reason_en!: string | null;

  unavailability_reason_fr!: string | null;

  open_length_min!: number;

  loop_duration_ms!: number;

  spot_length_ms!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  isAvailable(spots: number) {
    return this.free_spots_count >= spots && this.unavailable === 0;
  }

  static getAvailabilities: QueryFunction<Collection<ProductDayAvailability>, [ model: string, products: number[], from: DateString, to: DateString, capDate: string | null ]> = ({
                                                                                                                                                                                    queryKey,
                                                                                                                                                                                    signal,
                                                                                                                                                                                  }) => {
    const [ , productIds, from, to, capDate ] = queryKey;
    const route                               = makeRoute((new ProductDayAvailability()).basePath, HTTPMethod.post);

    return makeRequest<IProductDayAvailability[]>(route, null, {
      product_ids : productIds,
      from,
      to,
      known_before: capDate ?? null,
    }, undefined, undefined, signal)
      .then(({ data }) => Collection.ofType(ProductDayAvailability).make(data));
  };

  static getYearAvailabilities: QueryFunction<Collection<ProductDayAvailability>, readonly [ model: string, products: number[], year: number ]> = ({
                                                                                                                                                     queryKey,
                                                                                                                                                     signal,
                                                                                                                                                   }) => {
    const [ , productIds, year ] = queryKey;
    const route                  = makeRoute((new ProductDayAvailability()).basePath + '/_year', HTTPMethod.post);

    return makeRequest<IProductDayAvailability[]>(route, null, {
      product_ids: productIds,
      year,
    }, undefined, undefined, signal)
      .then(({ data }) => Collection.ofType(ProductDayAvailability).make(data));
  };
}
