/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - utils.ts
 */

import { getProperty }      from 'dot-prop';
import { DateTime }         from 'luxon';
import { AxiosResponse }    from 'axios';
import React                from 'react';
import { replaceEqualDeep } from '@tanstack/react-query';

export enum dayMasks {
  Monday    = 0b00000001,
  Tuesday   = 0b00000010,
  Wednesday = 0b00000100,
  Thursday  = 0b00001000,
  Friday    = 0b00010000,
  Saturday  = 0b00100000,
  Sunday    = 0b01000000,
}

/**
 * Confirm, in a TypeScript friendly way, that the given argument is a function
 * @param value
 */
export const isFunction = (value: unknown): value is Function => typeof value === 'function';

/**
 * @param prop
 * @param item
 * @param fallback
 * @return {unknown}
 */
const getProp = (prop: any, item: any, fallback = null) => {
  if (typeof prop === 'function') {
    return prop(item);
  }

  return getProperty(item, prop) ?? fallback;
};

/**
 * Appropriately sets the tab title
 */
const setTitle = (title = '') => {
  const suffix = 'Connect • Neo';

  if (title?.length > 0) {
    window.document.title = `${ title } • ${ suffix }`;
    return;
  }

  window.document.title = suffix;
};

// This gets
/**
 * @param {DateTime} date
 */
const getWeekNumber = (date: DateTime) => {
  return date.weekNumber;
};

const handleDownload = (name: string, mime: string) => (response: AxiosResponse) => {
  const blob    = new Blob([ response.data ],
    { type: mime },
  );
  const link    = document.createElement('a');
  link.href     = URL.createObjectURL(blob);
  link.download = name;
  link.click();
};

function range(length: number) {
  return [ ...Array(length).keys() ];
}

export function normalizeString(str: string) {
  return str.normalize('NFKD').replace(/\p{Diacritic}/gu, '').toLowerCase();
}

/**
 * Check that at least one pixel of the element is in the window
 * Check that the bottom of the element is not above the window, the left on the right of the window, the top on the bottom, the right on the left
 * @param el
 * @returns {boolean}
 */
export function isInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    /*&&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)*/
  );
}

export function formatZipcode(zip: string) {
  return zip.slice(0, 3) + ' ' + zip.slice(3, 6);
}

/**
 * Takes the result of a call to `useQueries` and perform a proper check for changes to prevent updates on every render
 * @param value
 *
 * @link https://github.com/TanStack/query/issues/6840#issuecomment-2385133632
 */
export function useStableQueries<T>(value: T) {
  const ref    = React.useRef(value);
  const stable = replaceEqualDeep(ref.current, value);
  React.useEffect(() => {
    ref.current = stable;
  }, [ stable ]);
  return stable;
}

export { getProp, setTitle, getWeekNumber, handleDownload, range };
