/*
 * Copyright 2024 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - FinancialCalendar.ts
 */

import { Model }      from 'library/Model';
import { DateString } from '../library/utils/dateTime';

export interface IFinancialCalendar {
  year: number,
  start: DateString
  end: DateString
  weeks: [ firstDay: DateString, lastDay: DateString ]
  months: [ firstDay: DateString, lastDay: DateString ]
}

class FinancialCalendarModel extends Model<IFinancialCalendar> {
  _slug = 'financial-calendar';

  basePath = '/v1/calendars/financial';

  key: keyof IFinancialCalendar = 'year' as const;

  routesAttributes = {
    create: {},
    save  : {},
  };

  attributesTypes: { [attr in keyof IFinancialCalendar]?: (sourceAttr: any) => IFinancialCalendar[attr] } = {};
}

export class FinancialCalendar extends FinancialCalendarModel implements IFinancialCalendar {

  year!: number;

  start!: DateString;

  end!: DateString;

  weeks!: [ firstDay: DateString, lastDay: DateString ];

  months!: [ firstDay: DateString, lastDay: DateString ];

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

}
